import { Component } from 'react';
import { ethers } from "ethers";
import { showError } from '../../helpers/alerts';
import FantasyAPI from '../../services/fantasy-api';

type MyProps = {};
type MyState = { selectedAddress: string };

class Web3WalletNoButton extends Component<MyProps, MyState> {
    private isAttemptConnect = false;

    private styles = {
        welcome: {
            paddingLeft: 5,
            fontSize: 11
        }
    };

    constructor(props: any) {
        super(props);

        this.state = {
            selectedAddress: ''
        };
    }

    async componentDidMount() {
        if (this.isAttemptConnect) {
            return;
        }

        try {
            this.isAttemptConnect = true;
            console.log('Trying to connect to web3 wallet');
            const provider = new ethers.BrowserProvider(window.ethereum);
            const accounts = await provider.send("eth_requestAccounts", []);

            console.log('Connected to wallet', accounts[0]);
            this.setState({ selectedAddress: accounts[0] });

            const api = new FantasyAPI();
            api.createPlayer(accounts[0]);
        } catch (e) {
            console.log('Exception connecting to web3 wallet', e);
            showError('Cannot connect to wallet');
        }
    }

    renderWeb3WalletInfo() {
        if (this.state.selectedAddress.length > 0) {
            return (
                <span style={this.styles.welcome}>Welcome {this.state.selectedAddress}</span>
            )
        } else {
            return (
                <span>Initializing...</span>
            )
        }
    }

    render() {
        return (
            <div>
                {this.renderWeb3WalletInfo()}
            </div>
        )
    }
}

export default Web3WalletNoButton;
