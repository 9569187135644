import { toast } from 'react-toastify';
import { ServerErrorResponse } from '../services/fantasy-api';

export function showServerResponseError(err: ServerErrorResponse) {
  return toast.error(err.message.join('\n'));
}

export function showError(msg: string) {
  return toast.error(msg);
}
