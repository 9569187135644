const songs = [
  {
    name: 'Vampire',
    contract: '0x64ce1f051C0f41A2DF52E1b0678B6f74bBFf83Ea',
    img: require('../assets/img/vampire.png')
  },
  {
    name: 'Thinkin Bout Me',
    contract: '0x88d677AC9866213cE06B65cd9dC2Cd6f0F183a49',
    img: require('../assets/img/thinkinboutme.png')
  },
  {
    name: 'Speak Now',
    contract: '0x56Af2db9D224D8c78A436012F29D14D4F3E19080',
    img: require('../assets/img/speaknow.png')

  },
  {
    name: 'Renaissance',
    contract: '0x4Ad732141632653c9c1576C1EF86116193F3A301',
    img: require('../assets/img/renaissance.png')

  },
  {
    name: 'Graduation',
    contract: '0x3239B66829aD5D6566821F38ce1BC816cFFA48eB',
    img: require('../assets/img/graduation.png')

  },
  {
    name: 'Stoney',
    contract: '0xA434b882715E66DBa2a385c45D0F79d181e49fe6',
    img: require('../assets/img/stoney.png')

  }
];

const SelfieUSD1 = '0x7D669063BfB2de909A3AF33D7315f8E5331De81c';

const poolInfo = {

  getUniswapUrl: (songName: string, buy: boolean) => {
    const song = songs.find(i => i.name === songName);
    if (!song) {
      return '';
    }

    const input = buy ? SelfieUSD1 : song.contract;
    const output = buy ? song.contract : SelfieUSD1;

    // https://app.uniswap.org/#/swap?chain=polygon&inputCurrency=0x64ce1f051C0f41A2DF52E1b0678B6f74bBFf83Ea&outputCurrency=0x7D669063BfB2de909A3AF33D7315f8E5331De81c
    return `https://app.uniswap.org/#/swap?chain=polygon&inputCurrency=${input}&outputCurrency=${output}`;
  },
  getImage: (songName: string) => {
    const song = songs.find(i => i.name === songName);
    if (!song) {
      return '';
    }

    return song.img;
  },
};

export default poolInfo;
