import MaterialCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import './card.scss';
import poolInfo from '../../helpers/pool-info';

type Props = {
    buy: boolean;
    songTitle: string;
}

export const LiquidityPool: React.FC<Props> = ({ songTitle, buy }) => {
    const linkUrl = poolInfo.getUniswapUrl(songTitle, buy);
    const title = `${buy ? 'Buy' : 'Sell'} ${songTitle}`;
    const img = poolInfo.getImage(songTitle);

    const styles = {
        title: {
            fontSize: 16,
            textDecoration: 'none'
        },
        desc: {
            fontSize: 14
        }
    };

    return (
        <a href={linkUrl} target="_blank">
            <MaterialCard className="card" sx={{ maxWidth: 150, maxHeight: 150, background: 'linear-gradient(to right bottom, #36EAEF, #6B0AC9)' }}>
                <CardMedia
                    sx={{ height: 75, backgroundSize: 'contain' }}
                    image={img}
                    title="Trade"
                />
                <CardContent>
                    <Typography style={styles.title} component="p">{title}</Typography>
                    {/* <Typography style={styles.desc}>{description}</Typography> */}
                </CardContent>
            </MaterialCard>
        </a>
    );
};
