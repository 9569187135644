import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LiquidityPool } from './components/liquidity-pool/liquidity-pool';
import { Grid } from '@mui/material';
// import Web3Wallet from './components/web3-wallet/web3-wallet';
import Web3WalletNoButton from './components/web3-wallet/web3-wallet-no-button';

function App() {
  const styles = {
    grid: {
      paddingTop: 5,
      paddingLeft: 10,
      paddingRight: 10
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer theme="dark" pauseOnHover />
      <Web3WalletNoButton />
      <Grid container spacing={2} style={styles.grid}>
        <Grid item xs={6}>
          <LiquidityPool songTitle="Vampire" buy={true}></LiquidityPool>
        </Grid>
        <Grid item xs={6}>
          <LiquidityPool songTitle="Vampire" buy={false}></LiquidityPool>
        </Grid>
        <Grid item xs={6}>
          <LiquidityPool songTitle="Thinkin Bout Me" buy={true}></LiquidityPool>
        </Grid>
        <Grid item xs={6}>
          <LiquidityPool songTitle="Thinkin Bout Me" buy={false}></LiquidityPool>
        </Grid>
        <Grid item xs={6}>
          <LiquidityPool songTitle="Speak Now" buy={true}></LiquidityPool>
        </Grid>
        <Grid item xs={6}>
          <LiquidityPool songTitle="Speak Now" buy={false}></LiquidityPool>
        </Grid>
      </Grid>

    </ThemeProvider>
  );
}

export default App;
