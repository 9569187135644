import axios, { AxiosInstance } from 'axios';

const baseConfig = {
  baseURL: process.env.REACT_APP_API_URL,
};

export default class FantasyAPI {
  private apiInstance: AxiosInstance;

  constructor() {
    this.apiInstance = axios.create(baseConfig);
  }

  public async createPlayer(address: string) {
    const res = await this.apiInstance.post(`${baseConfig.baseURL}/create-player/`, {
      address
    });

    if (res.status !== 200 && res.status !== 204) {
      console.log(`createPlayer ${res.status} error: ${res.statusText}`);
    }
  }
}

export type ServerErrorResponse = { error: string; message: string[]; statusCode: number };
